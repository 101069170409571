<template>
  <div class="wrapper">
    <div class="item">
      <span class="title">保单号</span>
      <span class="content">{{ dataObj.policyNo }}</span>
    </div>
    <div class="item">
      <span class="title">支付金额</span>
      <span class="content">{{ dataObj.price }}元</span>
    </div>
    <div class="item">
      <span class="title">投保生效日</span>
      <span class="content">{{ dataObj.startTime }}时</span>
    </div>
    <div class="item">
      <span class="title">申请退保日</span>
      <span class="content">{{ dataObj.endTime }}时</span>
    </div>
    <div class="item">
      <span class="title">退保金额</span>
      <span class="content">{{ dataObj.returnFee }}元</span>
    </div>
    <div class="btn-box">
      <p class="submit-btn" @click="submit">确认退保</p>
      <p class="submit-cancel" @click="cancel">取消</p>
    </div>
  </div>
</template>
<script>
import { surrender } from "@/utils/api.js";
export default {
  data() {
    return {
      dataObj: {},
    };
  },
  mounted() {
    this.dataObj = JSON.parse(sessionStorage.getItem("sendData"));
  },
  methods: {
    submit() {
      let parmas = {
        cancelInfos: [
          {
            policyNo: this.dataObj.policyNo,
            validDate: this.dataObj.endDate,
            validHour: this.dataObj.endHour.toString(),
          },
        ],
      };
      surrender(parmas).then((res) => {
        if (res.success) {
          var code = "";
          if (res.code == 200) {
            code = "200";
          } else {
            code = "500";
          }
          this.$router.push({
            path: "/surrenderStatus",
            query: {
              type: code,
            },
          });
        } else {
          this.$toast(res.message);
        }
      });
    },
    cancel() {
      this.$router.push({
        path: "/orderList",
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.wrapper {
  padding: 15px 15px;
  .item {
    padding-top: 22px;
    padding-bottom: 21px;
    border-bottom: 1px solid #f7f7f7;
    .title {
      display: inline-block;
      width: 84px;
      text-align: left;
      // height: 15px;
      // line-height: 15px;
      font-size: 16px;
      color: #333333;
      margin-right: 27px;
    }
    .content {
      font-size: 16px;
      color: #333333;
    }
  }
  .btn-box {
    margin-top: 43px;
    .submit-btn {
      width: 325px;
      height: 45px;
      background: #ff7418;
      border-radius: 8px;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 45px;
      margin: 0 auto;
    }
    .submit-cancel {
      width: 325px;
      height: 45px;
      border-radius: 8px;
      font-size: 15px;
      border: 1px solid #ff7418;
      font-weight: bold;
      color: #ff7418;
      margin: 0 auto;
      text-align: center;
      line-height: 45px;
      margin-top: 15px;
    }
  }
}
</style>